export const environment = {
  production: true,
  GRAPHQL_URL: 'https://4kxh2u5sq3.execute-api.eu-west-1.amazonaws.com/staging/portal/graphql',
  GRAPHQL_PROTECTED_URL: 'https://4kxh2u5sq3.execute-api.eu-west-1.amazonaws.com/staging/a-portal/graphql',
  GRAPHQL_SCHEMA_URL: 'https://4kxh2u5sq3.execute-api.eu-west-1.amazonaws.com/staging/portal/schema',
  BUILD_QUERY_KEY: '6a004d54ad43#14',
  CLOUDFRONT_API: 'https://d13z1irrbv506d.cloudfront.net/staging/portal/graphql',
  TRACKING_URL: 'https://staging-tr.businessclassconsolidator.com',
  AWS_PROJECT_REGION: 'eu-west-1',
  AWS_COGNITO_REGION: 'eu-west-1',
  AWS_USER_POOLS_ID: 'eu-west-1_fJHh29N6Y',
  AWS_USER_POOLS_WEB_CLIENT_ID: '3d1jgl4keafvo3m3h8oukendg5',
  OAUTH_DOMAIN: 'staging-portal.auth.eu-west-1.amazoncognito.com',
  AMPLIFY_REDIRECT_SIGN_IN: 'https://staging-q.bcconsolidator.com/login',
  AMPLIFY_REDIRECT_SIGN_OUT: 'https://staging-q.bcconsolidator.com/logout',
};